initializeCarousel();

function initializeCarousel() {
    showCarousel(0);
}

function showCarousel(currentIndex) {
    const cards = document.getElementsByClassName('hero-card');
    const indicators = document.getElementsByClassName(
        'hero-carousel-indicator'
    );
    for (let i = 0; i < cards.length; i++) {
        const card = cards[i];
        const indicator = indicators[i];
        if (currentIndex == i) {
            card.classList.add('hero-card--active');
            indicator.classList.add('hero-carousel-indicator--active');
        } else {
            card.classList.remove('hero-card--active');
            indicator.classList.remove('hero-carousel-indicator--active');
        }
    }
    currentIndex = (currentIndex + 1) % cards.length;
    setTimeout(() => showCarousel(currentIndex), 3000);
}
